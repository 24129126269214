<template>
    <div class="home">
        <van-row class="top">
            <van-col span="12" class="top_left">
                <van-button color="#ffffff" @click="handleClickProject">{{groupName}}
                    <van-icon name="play"/>
                </van-button>
            </van-col>
            <van-col span="4" class="top_right">
                <van-grid :column-num="1">
                    <!-- <van-grid-item icon="search" text="搜索" @click="handleClickSearch"/> -->
                    <!-- <van-grid-item icon="smile-comment-o" text="签到" @click="handleClickSignIn"/> -->
                    <van-grid-item icon="comment-o" text="客服"/>
                </van-grid>
            </van-col>
        </van-row>
        <!-- 项目模块儿弹出内容 -->
        <transition name="van-slide-right" mode="out-in">
            <router-view></router-view>
        </transition>
        <van-tabs v-model:active="active" color="#1989fa" swipeable sticky class="home_page">
            <!-- <van-tag type="warning" size="large" class="more" color="#ffffff"><van-icon name="bars" size="2.8rem" color="#656466"/></van-tag> -->
            <div v-for="(item,index) in listAll" :key="index">
                <van-tab :title="item.title" v-if="item.url=='index'">
                    <div class="banner-index">
                        <van-swipe class="my-swipe" :autoplay="3000" :height="170" indicator-color="#1989fa">
                            <van-swipe-item v-for="item in item.banner" :key="item.id">
                                <img :src="item.url" width="100%" style="display: block;" alt/>
                            </van-swipe-item>
                        </van-swipe>
                    </div>
                    <div class="time" v-if="day>0">
                        <van-icon name="underway-o" size="2.2rem" style="margin-right:.2rem"/>
                        距离考试还有<span class="day">{{day ? day : 0}}</span>天

                        <van-button round size="mini" color="linear-gradient(to right, #5c5c8d, #1989fa)" @click="subscribe">
                            订阅提醒
                            <van-icon name="arrow"/>
                        </van-button>
                    </div>
                    <div class="time" v-else>
                        <van-icon name="underway-o" size="2.2rem" style="margin-right:.2rem"/>
                        考试时间暂未公布

                        <van-button round size="mini" color="linear-gradient(to right, #5c5c8d, #1989fa)" @click="subscribe">
                            订阅提醒
                            <van-icon name="arrow"/>
                        </van-button>
                    </div>
                    <div class="navigation">
                        <van-grid :column-num="4">
                            <van-grid-item text="免费试听" icon="https://cdnoss.ksyt.com.cn/wxImages/assets/images/tb_shiting.png" @click="handler('Audition')"/>
                            <van-grid-item text="直播互动" icon="https://cdnoss.ksyt.com.cn/wxImages/assets/images/tb_zhibo.png" @click="showPopup" />
                            <van-grid-item text="选课中心" icon="https://cdnoss.ksyt.com.cn/wxImages/assets/images/tb_kecheng.png" @click="handler('Course')"/>
                            <van-grid-item text="我的资料" icon="https://cdnoss.ksyt.com.cn/wxImages/assets/images/tb_tiku.png" @click="handler('CourseData')"/>
                        </van-grid>
                    </div>
                    <div class="line"></div>
                    <div class="abs" v-if="item.liveBroadcastList.length > 0">
                        <van-row>
                            <van-col span="12" class="abs_left" v-for="item in item.liveBroadcastList" :key="item">
                                <van-image width="100%" height="18rem" :src="item.share_img"/>
                            </van-col>
                        </van-row>
                    </div>
                    <!-- <div class="line"></div> -->
                    <div class="column" v-if="item.commodityList.length > 0">
                        <van-cell center title="推荐课程" value="更多" is-link @click="handler('Course')" class="title"/>
                        <van-card
                                class="shop-card"
                                :desc="item2.commodity_content"
                                :title="item2.title"
                                :thumb="item2.photopath"
                                v-for="item2 in item.commodityList"
                                @click="handleClickCourseDetail(item2.detail_id)"
                        >
                            <template #tags>
                                <div class="tags">
                                    <van-tag plain round type="danger" v-for="item3 in item2.label">{{item3}}</van-tag>
                                </div>
                            </template>
                            <template #bottom>
                                <van-button round type="danger" size="mini">试听报名</van-button>
                            </template>
                        </van-card>
                    </div>
                    <div class="column" v-if="item.bookList.length > 0">
                        <van-cell center title="精品教辅资料" value="更多" is-link class="title"/>
                        <van-card
                                class="shop-card"
                                :desc="item2.desc"
                                :title="item2.title"
                                :thumb="item2.imgurl"
                                v-for="item2 in item.bookList"
                        >
                            <template #tags>
                                <div class="tags">
                                    <van-tag plain round type="warning" v-for="item3 in item2.label">{{item3}}</van-tag>
                                </div>
                            </template>
                            <template #bottom>
                                <van-button round type="warning" size="mini">咨询购买</van-button>
                            </template>
                        </van-card>
                    </div>
                    <div class="column">
                        <!-- <van-cell center title="推荐内容" value="更多" is-link class="title"/> -->
                        <!-- 推荐文章模块儿 -->
                        <!-- <div class="article" v-for="item2 in item.articleList">
                            <van-row>
                                <van-col span="16" class="arc_left">
                                    <van-cell>
                                        <template #title>
                                            <span class="custom-title">{{item2.title}}</span>
                                        </template>
                                        <template #label>
                                            <van-tag plain type="danger" class="flag">{{item2.label_id}}</van-tag>
                                            <span class="flag-title">{{item2.cate_name}}</span>
                                            <span class="flag-date"><van-icon name="stop"/>{{item2.create_time}}</span>
                                            <span class="flag-read">{{item2.views ? item2.views : 150}}阅读</span>
                                        </template>
                                    </van-cell>
                                </van-col>
                                <van-col span="8" class="arc_right">
                                    <van-image width="100%" height="10rem" :src="item2.img"/>
                                </van-col>
                            </van-row>
                        </div> -->
                       <!-- &lt;!&ndash; 推荐课程模块儿 &ndash;&gt; -->
<!--                       <div class="article" >-->
<!--                           <van-row>-->
<!--                               <van-col span="16" class="arc_left">-->
<!--                                   <van-cell>-->
<!--                                       <template #title>-->
<!--                                           <span class="custom-title">2022年中药全科（精品班）- 一年制考试班</span>-->
<!--                                       </template>-->
<!--                                       <template #label>-->
<!--                                           <van-tag plain type="primary" class="flag">课程</van-tag>-->
<!--                                           <span class="flag-title">256人在学</span>-->
<!--                                           &lt;!&ndash; &lt;!&ndash; <span class="flag-date">256人在学</span>-->
<!--                                           <span class="flag-read">3909阅读</span> &ndash;&gt; &ndash;&gt;-->
<!--                                       </template>-->
<!--                                   </van-cell>-->
<!--                               </van-col>-->
<!--                               <van-col span="8" class="arc_right">-->
<!--                                   <van-image width="100%" height="10rem" :src="require('../assets/images/banci.png')"/>-->
<!--                               </van-col>-->
<!--                           </van-row>-->
<!--                       </div>-->
<!--                       <div class="article">-->
<!--                           <van-row>-->
<!--                               <van-col span="16" class="arc_left">-->
<!--                                   <van-cell>-->
<!--                                       <template #title>-->
<!--                                           <span class="custom-title">2022年中药全科（精品班）- 一年制考试班</span>-->
<!--                                       </template>-->
<!--                                       <template #label>-->
<!--                                           <van-tag plain type="primary" class="flag">课程</van-tag>-->
<!--                                           <span class="flag-title">256人在学</span>-->
<!--                                           &lt;!&ndash; &lt;!&ndash; <span class="flag-date">256人在学</span>-->
<!--                                           <span class="flag-read">3909阅读</span> &ndash;&gt; &ndash;&gt;-->
<!--                                       </template>-->
<!--                                   </van-cell>-->
<!--                               </van-col>-->
<!--                               <van-col span="8" class="arc_right">-->
<!--                                   <van-image width="100%" height="10rem" :src="require('../assets/images/banci.png')"/>-->
<!--                               </van-col>-->
<!--                           </van-row>-->
<!--                       </div>-->
<!--                        &lt;!&ndash; 其他内容模块儿 &ndash;&gt;-->
<!--                        <div class="article">-->
<!--                            <van-row>-->
<!--                                <van-col span="16" class="arc_left">-->
<!--                                    <van-cell>-->
<!--                                        <template #title>-->
<!--                                            <span class="custom-title">2022年执业药师考试名师直播考试帮你指点迷津</span>-->
<!--                                        </template>-->
<!--                                        <template #label>-->
<!--                                            &lt;!&ndash; <van-tag plain type="warning" class="flag">试听</van-tag> &ndash;&gt;-->
<!--                                            <span class="flag-title">考试动态</span>-->
<!--                                            <span class="flag-date"><van-icon name="stop"/>6月23日</span>-->
<!--                                            <span class="flag-read">3909阅读</span>-->
<!--                                        </template>-->
<!--                                    </van-cell>-->
<!--                                </van-col>-->
<!--                                <van-col span="8" class="arc_right">-->
<!--                                    <van-image width="100%" height="10rem" :src="require('../assets/images/zhibo1.jpg')"/>-->
<!--                                </van-col>-->
<!--                            </van-row>-->
<!--                        </div>-->
<!--                        <div class="article">-->
<!--                            <van-row>-->
<!--                                <van-col span="16" class="arc_left">-->
<!--                                    <van-cell>-->
<!--                                        <template #title>-->
<!--                                            <span class="custom-title">2022年执业药师考试名师直播考试帮你指点迷津</span>-->
<!--                                        </template>-->
<!--                                        <template #label>-->
<!--                                            &lt;!&ndash; <van-tag plain type="warning" class="flag">试听</van-tag> &ndash;&gt;-->
<!--                                            <span class="flag-title">考试动态</span>-->
<!--                                            <span class="flag-date"><van-icon name="stop"/>6月23日</span>-->
<!--                                            <span class="flag-read">3909阅读</span>-->
<!--                                        </template>-->
<!--                                    </van-cell>-->
<!--                                </van-col>-->
<!--                                <van-col span="8" class="arc_right">-->
<!--                                    <van-image width="100%" height="10rem" :src="require('../assets/images/zhibo1.jpg')"/>-->
<!--                                </van-col>-->
<!--                            </van-row>-->
<!--                        </div>-->
                        <!-- 课程试听模块儿 -->
                        <!-- <div class="article" v-for="item2 in item.courseWareList">
                            <van-row>
                                <van-col span="24" class="arc_mid">
                                    <van-cell>
                                        <template #title>
                                            <span class="custom-title">{{item2.video_title}}</span>
                                            <van-image fit="contain" radius=".6rem" width="100%" height="auto"
                                                       :src="item2.photopath">
                                                <van-tag type="warning" size="large" class="class_hour">{{item2.video_duration}}</van-tag>
                                            </van-image>
                                            <van-icon name="play-circle-o" size="8rem" color="#ffffff" class="play"/>
                                        </template>
                                        <template #label>
                                            <van-tag plain type="warning" class="flag">试听</van-tag>
                                            <span class="flag-title">{{item2.title}}</span>
                                            <span class="flag-date"><van-icon name="stop"/>讲师:{{item2.username}}</span>
                                            <span class="flag-read">讲师：赵俊峰</span>
                                        </template>
                                    </van-cell>
                                </van-col>
                            </van-row>
                        </div> -->
                        <!-- 其他栏目模块儿 -->
<!--                        <div class="article">-->
<!--                            <van-row>-->
<!--                                <van-col span="16" class="arc_left">-->
<!--                                    <van-cell>-->
<!--                                        <template #title>-->
<!--                                            <span class="custom-title">2022年执业药师考试名师直播考试帮你指点迷津</span>-->
<!--                                        </template>-->
<!--                                        <template #label>-->
<!--                                            &lt;!&ndash; <van-tag plain type="warning" class="flag">试听</van-tag> &ndash;&gt;-->
<!--                                            <span class="flag-title">其他</span>-->
<!--                                            <span class="flag-date"><van-icon name="stop"/>6月23日</span>-->
<!--                                            <span class="flag-read">3909阅读</span>-->
<!--                                        </template>-->
<!--                                    </van-cell>-->
<!--                                </van-col>-->
<!--                                <van-col span="8" class="arc_right">-->
<!--                                    <van-image width="100%" height="10rem" :src="require('../assets/images/zhibo1.jpg')"/>-->
<!--                                </van-col>-->
<!--                            </van-row>-->
<!--                        </div>-->
<!--                        <div class="article">-->
<!--                            <van-row>-->
<!--                                <van-col span="16" class="arc_left">-->
<!--                                    <van-cell>-->
<!--                                        <template #title>-->
<!--                                            <span class="custom-title">2022年执业药师考试名师直播考试帮你指点迷津</span>-->
<!--                                        </template>-->
<!--                                        <template #label>-->
<!--                                            &lt;!&ndash; <van-tag plain type="warning" class="flag">试听</van-tag> &ndash;&gt;-->
<!--                                            <span class="flag-title">其他</span>-->
<!--                                            <span class="flag-date"><van-icon name="stop"/>6月23日</span>-->
<!--                                            <span class="flag-read">3909阅读</span>-->
<!--                                        </template>-->
<!--                                    </van-cell>-->
<!--                                </van-col>-->
<!--                                <van-col span="8" class="arc_right">-->
<!--                                    <van-image width="100%" height="10rem" :src="require('../assets/images/zhibo1.jpg')"/>-->
<!--                                </van-col>-->
<!--                            </van-row>-->
<!--                        </div>-->
                    </div>

                </van-tab>
                <!-- 直播模块 -->
                <van-tab :title="item.title" v-else-if="item.url=='liveBroadcast' && item.liveBroadcastList.length > 0">

                    <div class="column">
                        <div class="article" v-for="item2 in item.liveBroadcastList">
                            <van-row>
                                <van-col span="24" class="arc_mid">
                                    <van-cell>
                                        <template #title>
                                            <span class="custom-title">{{item2.name}}</span>
                                            <van-image fit="cover" radius=".6rem" width="100%" height="25rem" :src="item2.share_img" >
                                                <van-tag type="danger" size="large" class="live_flag" v-if="item2.type == 1">购课直播</van-tag>
                                                <van-tag type="success" size="large" class="live_flag" v-else>免费直播</van-tag>
                                            </van-image>
<!--                                            <van-button round icon="play" class="play_buy play_green" @click="clickMsg">进入直播</van-button>-->
                                            <div v-if="item2.live_status == 102">
                                                <van-button round icon="play" size="small" class="play_buy play_green" @click="showDetail(item2.id,item2.roomid)">{{item2.subscribeInfo?'进入直播':'开播提醒'}}</van-button>
                                            </div>
                                            <div v-else>
                                                <van-button round icon="play" size="small" class="play_buy play_green" @click="showDetail(item2.id,item2.roomid)">进入直播</van-button>
                                            </div>
                                        </template>
                                        <template #label>
                                            <span class="flag-title">直播互动</span>
                                            <span class="flag-date"><van-icon name="stop"/>{{item2.start_time}}-{{item2.end_time}}</span>
                                        </template>
                                    </van-cell>
                                </van-col>
                            </van-row>
                        </div>
                    </div>
                </van-tab>
                <!-- <van-tab :title="item.title" v-else>
                    <div class="column" v-if="item.data.length > 0">
                        文章内容模块儿
                        <div class="article" v-for="item2 in item.data">
                            <van-row>
                                <van-col span="16" class="arc_left">
                                    <van-cell>
                                        <template #title>
                                            <span class="custom-title">{{item2.title}}</span>
                                        </template>
                                        <template #label>
                                            <span class="flag-title">{{item2.cate_name}}</span>
                                            <span class="flag-date"><van-icon name="stop"/>{{item2.create_time}}</span>
                                            <span class="flag-read">{{item2.views ? item2.views : 150}}阅读</span>
                                        </template>
                                    </van-cell>
                                </van-col>
                                <van-col span="8" class="arc_right">
                                    <van-image width="100%" height="10rem" :src="item2.img"/>
                                </van-col>
                            </van-row>
                        </div>
                    </div>
                </van-tab> -->
            </div>
        </van-tabs>
        <van-popup v-model:show="show">
            <wx-open-launch-weapp id="launch-btn" username="gh_5ea431644090" :path="url" env-version="release">
                <script type="text/wxtag-template">
                    <style>.btn { padding:0rem 1rem; color:#ffffff;font-size:1.6rem;background-color:#1989fa;width:16rem;height:6rem;border-radius:5rem;z-index: 99999; }</style>
                    <button class="btn">打开小程序</button>
                </script>
            </wx-open-launch-weapp>
        </van-popup>

    </div>
</template>
<script>
    // @ is an alias to /src
    // import HelloWorld from '@/components/HelloWorld.vue'
    // import axios from "axios"
    // import { ref } from 'vue';

    import {
        GetHomeList,
        GetHomeList2,
        onShareUrl,
        examinationTime,
        getCommodityIndexList,
        liveReservationStatus, appletUrl ,subscribeUrl
    } from '@/request/api'
    import getApp from '@/App'
    import Qs from "qs";
    import wx from 'weixin-js-sdk';
    import {Toast} from "vant";

    export default {
        beforeRouteEnter(to, from, next) {
            console.log(to,from);
            next();
        },
        name: 'Index',
        metaInfo() {
            return {
                title: this.title, //设置页面title
            }
        },
        // metaInfo: {
        //
        //     meta: [{                 //设置页面meta
        //         name: 'keyWords',
        //         content: '关键词'
        //     },
        //         {
        //             name: "description",
        //             content: "描述",
        //         },
        //     ]
        // },
        data() {
            return {
                title: this.getBranchCompany(),
                active: "",
                active1: "",
                show: false,
                item: "",
                SearchValue: "",
                //轮播图数组
                banner: [],
                columnIndexList: [
                    {
                        text: "免费试听",
                        icon: "n",
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/tb_shiting.png",
                        link: 'handleClickFreeAudition'
                    },
                    {
                        text: "直播互动",
                        icon: "w",
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/tb_zhibo.png",
                        link: 'Live'
                    },
                    {
                        text: "选课中心",
                        icon: "j",
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/tb_kecheng.png",
                        link: 'Course'
                    },
                    {
                        text: "领取资料",
                        icon: "g",
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/tb_tiku.png",
                        link: 'handleClickMyInfo'
                    },
                ],
                listAll: [],
                groupName: (localStorage.getItem("groupName") ? localStorage.getItem("groupName") : '未选择'),
                day: 0,
                wxHtml: '',
                isWx: this.isWeiXin(),
                branch_id: this.getBranch(),
                admin_id: this.getAdminId(),
                group_id : this.getGroupId(),
                subject_id : this.getSubjectId(),
                url: 'pages/liveBroadcast/liveBroadcast?branchCenter='+this.getBranch()+'&adminIdCenter='+this.getAdminId()+'&groupCenter='+this.getGroupId()+'&subjectCenter='+this.getSubjectId(),
                commodityList: [],
                photopath: 'https://cdnoss.ksyt.com.cn/school/51e7b00a0c42d29e1c7ce182ca20634.jpg',
                isShow:false
            };
        },
        setup() {
            const active = ref(0);
            return {active};
        },
        updated() {
            console.log('update');
        },
        methods: {
            handleClickSearch() {
                // this.$router.push('/index/searchPopup')
                // 修改vuex中的 isShowPopupShow 值为true
                // this.$store.commit("changeIsShowPopupShow", true)
                // console.log("搜索按钮被点击");
                Toast('暂未开放');
            },
            handleClickProject() {
                this.$router.push('/index/project')
                // 修改vuex中的 isShowPopupShow 值为true
                // this.$store.commit("show", true)
                // console.log("搜索按钮被点击");
            },
            handleClickSignIn() {
                // this.$router.push('/signIn')
                // 修改vuex中的 isShowPopupShow 值为true
                // this.$store.commit("changeIsShowPopupShow", true)
                // console.log("签到按钮被点击");
                Toast('暂未开放');
            },
            toast(){
                Toast('功能开发中');
                // 加载中
                // Toast.loading({
                //     message: '加载中...',
                //     forbidClick: true,
                // });
                // 成功/失败/关闭
                // Toast.success('成功文案');
                // Toast.fail('失败文案');
                // Toast.clear;
                // 第二个加载样式
                // Toast.loading({
                //     message: '加载中...',
                //     forbidClick: true,
                //     loadingType: 'spinner',
                // });
                // 自定义图标
                // Toast({
                // message: '自定义图标',
                // icon: 'like-o',
                // position: 'top',
                // duration: 1000,
                // });


            },
            showPopup() {
                this.$router.push({
                    name:'LiveBroadcast',
                    params:{

                    }
                })
                // this.show = true;
            },
            Dialog1() {
                Toast('仅支持微信打开');
            },

            handler(link) {
                if(!link) {
                    return false;
                }
                this.$router.push({
                    name:link,
                    params:{

                    }
                })
            },
            handleClickFreeAudition() {
                this.$router.push('/freeAudition')
                // this.$router.push('/freeAudition11')
                // 修改vuex中的 isShowPopupShow 值为true
                // this.$store.commit("changeIsShowPopupShow", true)
                console.log("免费试听按钮被点击");
            },
            homeList() {
                this.getUrlParameter()
                let branchId = this.getBranch();
                let groupId = this.getGroupId();
                let subjectId = this.getSubjectId();
                let that = this;
                if(this.getToken()) {
                    GetHomeList2()
                        .then(res => {
                            if(res.data.code == 1) {
                                that.listAll = res.data.data.data;
                            }
                        })
                        .catch(err => {
                            console.log("error");
                            console.log(err);
                        })
                } else {
                    GetHomeList({branchId,groupId,subjectId})
                        .then(res => {
                            if(res.data.code == 1) {
                                that.listAll = res.data.data.data;
                                localStorage.setItem("adminId", res.data.data.admin);
                                localStorage.setItem("title", res.data.data.company);
                                that.title = res.data.data.company;
                            }
                        })
                        .catch(err => {
                            console.log("error");
                            console.log(err);
                        })
                }

            },
            examinationTime() {
                let groupId = this.getGroupId();
                if(groupId) {
                    examinationTime({groupId})
                        .then(res => {
                            if(res.data.code == 1) {
                                this.day = res.data.data.time;
                            }

                        })
                        .catch(err => {
                            console.log("error");
                            console.log(err);
                            Toast.fail('请求错误');
                        })
                }

            },
            onWxConfig() {
                let that = this;
                let branchId = that.getBranch();
                // alert(location.href.split('#')[0]);
                // alert(window.location.origin);
                onShareUrl({url: location.href.split('#')[0],branchId})
                    .then(res => {
                        if (res.data.code == 1) {
                            wx.config({
                                debug: false,// 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                                appId: res.data.data.sdk.appId,         // 必填，公众号的唯一标识，填自己的！
                                timestamp: res.data.data.sdk.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
                                nonceStr: res.data.data.sdk.nonceStr,   // 必填，生成签名的随机串
                                signature: res.data.data.sdk.signature, // 必填，签名，见附录1
                                jsApiList: [
                                    'checkJsApi',
                                    'onMenuShareQQ',
                                    'onMenuShareWeibo',
                                    'onMenuShareQZone',
                                    'updateAppMessageShareData',
                                    'updateTimelineShareData',
                                    // 'onMenuShareTimeline',
                                    // 'onMenuShareAppMessage',
                                    'wx-open-launch-weapp'
                                ],
                                openTagList: ['wx-open-launch-weapp']
                            })
                            let shareConfig = {
                                title:  res.data.data.share.fx_share_title,
                                desc: res.data.data.share.fx_share_desc,
                                link:  that.getUrlParameter(),
                                imgUrl: res.data.data.share.fx_share_logo,
                                type: '',
                                dataUrl: '',
                                success: function (r) {
                                    console.log("分享成功"+JSON.stringify(r));
                                },
                                cancel: function (rr) {
                                    console.log('分享错误'+JSON.stringify(rr));
                                }
                            }
                            that.getIcoUrl(res.data.data.share.ico);
                            wx.ready(function () {
// that.getHtml();
                                //分享到朋友圈
                                // wx.onMenuShareTimeline(shareConfig);
                                //分享给朋友
                                // wx.onMenuShareAppMessage(shareConfig);
                                wx.onMenuShareQQ(shareConfig); // QQ
                                wx.updateAppMessageShareData(shareConfig);
                                wx.updateTimelineShareData(shareConfig);
                            });
                            wx.error(function(res){
                                console.log(123);
                                console.log(res);
                                // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
                            })
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            },
            clickMsg() {
                Toast.success('请微信搜索“学习中心助手”小程序');
            },
            commodityIndexList() {
                let branchId = this.getBranch();
                let groupId = this.getGroupId();
                let subjectId = this.getSubjectId();
                let that = this;
                getCommodityIndexList({branchId,groupId})
                    .then(res => {
                        if(res.data.code == 1) {
                            that.commodityList = res.data.data.data;
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            },
            handleClickCourseDetail(id) {
                if(this.getToken()) {
                    this.$router.push({
                        name: 'CourseDetail',
                        params:{
                            id
                        },
                        query:{
                            id
                        }
                    })
                } else {
                    Toast.fail('请先登录');
                    this.$router.push({
                        name: 'User',
                        query: {redirect: this.$route.name,isShowModal:true}
                    })
                }

                // 修改vuex中的 isShowPopupShow 值为true
                // this.$store.commit("changeIsShowPopupShow", true)
            },

            // getHtml() {
            //     let script = document.createElement('script')
            //     script.type = 'text/wxtag-template'
            //     script.text = `<button class="btn blue mt1" style="height: 50px;width: 100px;">跳转小程序</button>`
            //     this.wxHtml =
            //         `<wx-open-launch-weapp style="height: 50px;width: 100px;"
		    //                         id="launchwx"
		    //                         ref="launchwx"
			// 					    appid="wxcdb8ee4ca68e6447"
		    //                         username="gh_5ea431644090"
		    //                         path="pages/index/index.html"
		    //                       >${script.outerHTML}
		    //                     </wx-open-launch-weapp>`
            //     this.$nextTick(() => {
            //         let launchButton = document.getElementById('launchwx')
            //         console.log(this.$refs.launchwx);
            //         console.log(launchButton);
            //         this.$refs.launchwx.addEventListener('launch', function(e) {
            //             console.log('success', e)
            //         })
            //
            //         this.$refs.launchwx.addEventListener('error', function(e) {
            //             console.log('fail', e)
            //         })
            //     })
            // }
            //进入直播
            showDetail(id,roomId) {
                if(this.getToken()) {
                    let that = this;
                    liveReservationStatus({room_id:id})
                        .then(res => {
                            console.log(res);
                            if(res.data.code == 1) {
                                //进入直播
                                that.getUrl(id);
                            } else {
                                Toast.fail(res.data.msg);
                            }
                        })
                        .catch(err => {
                            console.log("error");
                            console.log(err);
                            Toast.fail('请求错误');
                        })
                } else {
                    Toast.fail('请先登录');
                    this.$router.push({
                        name: 'User',
                        query: {redirect: this.$route.name,isShowModal:true}
                    })
                }
            },
            getUrl(id) {
                let that = this;
                appletUrl({id})
                    .then(res => {
                        if(res.data.code == 1) {
                            //进入直播
                            window.location.href=res.data.data.url;
                        } else {
                            Toast.fail(res.data.msg);
                        }
                    })
                    .catch(err => {
                        Toast.fail('请求直播链接错误');
                    })
            },
            subscribe(id) {
                let that = this;
                if(!that.getToken()) {
                    Toast.fail('请先登录');
                    this.$router.push({
                        name: 'User',
                        query: {redirect: that.$route.name,isShowModal:true}
                    })
                    return false;
                }

                subscribeUrl({id})
                    .then(res => {
                        if(res.data.code == 1) {
                            //进入直播
                            window.location.href=res.data.data.url;
                        } else {
                            Toast.fail(res.data.msg);
                        }
                    })
                    .catch(err => {
                        Toast.fail('操作错误');
                    })
            },
        },
        created() {
            console.log(window.getEdition());
            // let userInfo = this.getUserInfo();
            // console.log(localStorage.getItem("groupName") );
            // console.log(window.location.href);
            // request.get("/index/index")
            // this.homeList();
            this.onWxConfig();
            this.title = this.getBranchCompany()
        },
        components: {},
        mounted() {
            // console.log(2);
            this.homeList();
            this.examinationTime();
            this.groupName = (localStorage.getItem("groupName") ? localStorage.getItem("groupName") : '未选择');
            this.title = this.getBranchCompany()
            // var btn = document.getElementById("launch-btn");
            // console.log();
            // btn.addEventListener("launch", function (e) {
            //     console.log("success");
            // });
            // btn.addEventListener("error", function (e) {
            //     alert("小程序打开失败");
            //     console.log("fail", e.detail);
            // });
        },
    }
</script>
<style lang="less">
    .home {
        background-color: #ffffff;
        // overflow: hidden;
        padding: 0 0.8rem 0.8rem 0.8rem;
    }

    .top {
        position: fixed;
        top: 0;
        width: 100%;
        height: 6rem;
        background-color: #ffffff;
        z-index: 9999;
    }

    .top .top_left .van-button {
        font-size: 2.2rem;
        height: 100%;
    }

    .top .top_left .van-button__text {
        color: #323233;
        font-weight: 600;
        font-size: 2.2rem;
        margin-left: .5rem;
    }

    .top .top_left .van-button .van-icon {
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
    }

    .top .top_left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }

    .top .top_right {
        position: absolute;
        right: 0;
        height: 100%;
    }

    .top .top_right .van-grid {
        height: 100%;
    }

    .top .top_right .van-grid-item__content::after {
        border-width: 0;
    }

    .top .top_right .van-grid-item__content .van-grid-item__text {
        // margin-top: 2px;
        font-size: 1.6rem;
    }

    .top .top_right .van-grid-item__content .van-icon {
        font-size: 2.4rem;
    }

    .popup-shadow {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, .5);
        z-index: 20;
    }
    // .van-overlay {
    //     z-index: 99998 !important;
    // }
    .popup-shadow-project {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, .5);
        z-index: 20;
    }

    // .slide-enter, .slide-leave-to {
    //   // 过渡之前的样式写在这里
    //    right: -100%;
    // }
    // .slide-enter-active,.slide-leave-active  {
    //   // 过渡属性写在这里
    //   transition: all .3s;
    // }
    // .slide-enter-to, .slide-leave {
    //   // 过渡之后的样式写在这里
    //    right: 0;
    // }

    .home_page .van-tabs__content {
        margin-top: 11.6rem;
    }

    .home_page .more {
        position: fixed;
        right: 0;
        height: 6rem;
        top: 5.3rem;
        z-index: 9998;
        padding: 0 1.4rem 0 1.6rem !important;
        border-radius: 0;
    }

    .home_page .van-sticky {
        position: fixed;
        top: 5.9rem;
        padding: 0 0.8rem 0rem 0rem;
        z-index: 9998;
        width: 100%;
    }

    .home_page .van-sticky--fixed {
        position: fixed;
        top: 5.9rem;
        padding: 0 0.8rem 0.8rem 0.8rem;
        z-index: 9998;
    }

    .home_page .van-tabs__wrap {
        // padding-bottom: 6px;
        height: 5.4rem !important;
    }

    .home_page .van-tabs__line {
        bottom: 0.5rem;
        left: 0;
        z-index: 1;
        width: 3.2rem;
        height: 0.5rem;
        border-radius: 2rem;
    }

    .home_page .van-tabs__nav--line.van-tabs__nav--complete {
        margin-right: 4rem;
    }

    .home_page .van-tab--active .van-tab__text {
        font-size: 2.4rem;
        font-weight: 600;
        color: #1989fa;
        padding-bottom: .4rem;
    }

    .home_page .van-tabs__wrap--scrollable .van-tab {
        padding: 0 1.4rem !important;
    }

    .banner-index {
        padding: .6rem;
        border-radius: .8rem;
    }

    .banner-index .van-swipe {
        border-radius: .8rem;
    }

    .banner-index .my-swipe .van-swipe__indicators {
        bottom: .8rem;
    }

    .banner-index .my-swipe .van-swipe__indicators .van-swipe__indicator {
        width: .8rem;
        height: .8rem;
        margin-right: .8rem;
        background-color: #696969;
    }

    .banner-index .my-swipe .van-swipe__indicators .van-swipe__indicator--active {
        width: 1.6rem;
        border-radius: 2rem;
    }

    // .my-swipe .van-swipe-item {
    //     color: #fff;
    //     font-size: 20px;
    //     line-height: 150px;
    //     text-align: center;
    //     background-color: #39a9ed;
    //   }


    .time {
        height: 5rem;
        padding: .8rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 2rem;
        // font-weight: 600;
        color: #323233;
    }

    .time .day {
        font-weight: 600;
        font-size: 2.4rem;
        padding: 0 .5rem;
    }

    .time .van-button {
        position: absolute;
        right: 1rem;
        padding: 0 .4rem 0 0.8rem;
        height: 2.4rem;
        font-size: 1.4rem;
    }

    .navigation .van-grid {
        margin: .6rem 0;
    }

    .navigation .van-hairline--top::after {
        border-width: 0;
    }

    .navigation .van-grid-item__content {
        padding: .8rem;
    }

    .navigation .van-grid-item__content::after {
        border-width: 0;
    }

    .navigation .van-grid .van-grid-item .van-icon {
        font-size: 5.4rem;
    }

    .navigation .van-grid .van-grid-item .van-grid-item__text {
        font-size: 1.6rem;
        margin-top: .2rem;
    }

    .line {
        height: .6rem;
        background-color: #f5f5f5;
    }

    .abs {
        margin: 1rem .6rem;
        height: 10rem;
        background-color: #ffffff;
    }

    .abs .van-hairline--top::after {
        border-width: 0;
    }

    .abs .van-grid-item__content {
        padding: .6rem .5rem;
    }

    .abs .van-grid-item__content img {
        width: 100%;
        border-radius: .8rem;
    }

    .abs .abs_left {
        padding: .6rem .5rem .6rem 0rem;
    }

    .abs .abs_left .van-image img {
        border-radius: .8rem;
    }

    .abs .abs_right {
        padding: .6rem 0rem .6rem .5rem;
    }

    .abs .abs_right .van-image {
        padding: .2rem 0px;
    }

    .abs .abs_right .van-image img {
        border-radius: .5rem;
    }
    
    .column{
        padding-bottom: 3rem;
    }

    .column .van-cell::after {
        border-width: 0;
    }

    .column .title {
        padding: 1.8rem .6rem 1rem .6rem;
        height: 6rem;
        line-height: 6rem;
    }

    .column .title .van-cell__title {
        font-size: 2.4rem;
        font-weight: 600;
    }

    .column .title .van-cell__value {
        font-size: 1.8rem;
        overflow: visible;
    }

    .column .title .van-icon {
        font-size: 2rem;
        line-height: 2rem;
        height: 2rem;
    }

    .column .title1 {
        padding: .6rem .6rem 0 .6rem;
        height: 3.6rem;
    }

    .column .title1 .van-cell__title {
        font-size: 2.2rem;
        font-weight: 600;
        line-height: 2.4rem;
    }

    .column .title1 .van-cell__value {
        font-size: 1.8rem;
        height: 4rem;
        overflow: visible;
    }

    .column .shop-card {
        background-color: #ffffff;
        height: 14rem;
        padding: 0 0.6rem;
        margin-bottom: 1.8rem;
    }

    .column .shop-card .van-card__thumb {
        width: 13.5rem;
        height: 14rem;
        margin-right: .6rem;
    }

    .column .shop-card .van-card__thumb img {
        border-radius: .8rem;
    }

    .column .shop-card .van-card__title {
        font-size: 2rem;
        line-height: 3rem;
        height: 3.2rem;
        max-height: 3.2rem;
    }

    .column .shop-card .van-card__desc {
        font-size: 1.8rem;
        line-height: 3rem;
        height: 3rem;
        max-height: 3rem;
    }

    .column .shop-card .tags {
        overflow: hidden;
        text-overflow: clip;
        white-space: nowrap;
        height: 3.6rem;
        line-height: 3rem;
    }

    .column .shop-card .tags .van-tag {
        font-size: 1.4rem;
        margin-top: 1rem;
        margin-right: .4rem;
        padding: 0 .8rem;
        height: 2.4rem;
    }

    .column .shop-card .van-card__bottom {
        height: 3.2rem;
    }

    .column .shop-card .van-card__bottom .van-button {
        float: right;
        padding: 0 .8rem;
        font-size: 1.4rem;
        height: 3rem;
    }

    .article {
        margin-bottom: .8rem;
        // padding-bottom: 0px;
        // height: 90px;
        background-color: #ffffff;
    }

    .article::after {
        content: '';
        width: 96%;
        height: .1rem;
        display: block;
        margin: .6rem auto;
        border-bottom: .1rem solid #f5f5f5
    }

    .article .arc_left {
        padding: .6rem 0 .6rem .5rem;
    }

    .article .arc_left .van-cell {
        padding: 0 .2rem;
    }

    .article .arc_right {
        padding: .6rem .6rem .6rem .5rem;
    }

    .article .arc_right .van-image {
        padding: .2rem 0px;
    }

    .article .arc_right .van-image img {
        border-radius: .5rem;
    }


    .column .article .arc_left .van-cell .custom-title {
        font-size: 2rem;
        height: 6rem;
        line-height: 3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }

    .column .article .arc_left .van-cell .van-cell__label {
        margin-top: 1rem;
        height: 3.4rem;
        line-height: 3rem;
    }

    .column .article .arc_left .van-cell .van-cell__label .flag {
        margin-right: .4rem;
        font-size: 1.6rem;
        line-height: 2rem;
        padding: 0 .3rem;
    }

    .column .article .arc_left .van-cell .van-cell__label .flag::before {
        border: 0.1rem solid;
        border-radius: .3rem;
    }

    .column .article .arc_left .van-cell .van-cell__label .flag-title {
        margin-right: 0;
        font-size: 1.6rem;
    }

    .column .article .arc_left .van-cell .van-cell__label .flag-date {
        margin-right: .4rem;
        font-size: 1.6rem;
    }

    .column .article .arc_left .van-cell .van-cell__label .flag-read {
        margin-left: .8rem;
        font-size: 1.6rem;
    }


    .article .arc_mid {
        padding: .6rem;
    }

    .article .arc_mid .van-cell {
        padding: 0 .2rem;
    }

    .column .article .arc_mid .van-cell .van-cell__title .custom-title {
        height: 3.5rem;
        line-height: 3.2rem;
        font-size: 2rem;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .column .article .arc_mid .van-cell .van-cell__title .play {
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        position: absolute;
        top: 44%;
        left: 40%;
        margin: 0 auto;
        background-color: rgba(0, 0, 0, .5);
        border-radius: 100%;
    }

    .column .article .arc_mid .van-cell .van-cell__title .play_buy {
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        position: absolute;
        top: 45%;
        left: 34%;
        margin: 0 auto;
        color: #ffffff;
        background-color: rgba(0, 0, 0, .5);
        font-size: 2rem;
        height: 4.5rem;
        padding: 0 1.5rem;
        border-color: rgba(0, 0, 0, .5);;
    }

    .column .article .arc_mid .van-cell .van-cell__title .play_buy .van-button__content {
        font-size: 2rem;
    }

    .column .article .arc_mid .van-cell .van-cell__title .class_hour {
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        position: absolute;
        bottom: 3%;
        right: 2%;
        margin: 0 auto;
        background-color: rgba(0, 0, 0, .5);
        font-size: 2rem;
        height: 3rem;
        padding: 0 .8rem;
        border-radius: .6rem;
    }

    .column .article .arc_mid .van-cell .van-cell__title .live_flag {
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        position: absolute;
        top: 4%;
        left: 2%;
        margin: 0 auto;
        font-size: 2rem;
        height: 3rem;
        padding: 0 .8rem;
        border-radius: .6rem;
    }

    .play_green {
        color: #00ff08 !important;
    }

    .column .article .arc_mid .van-cell .custom-title {
        font-size: 2rem;
        // height: 50px;
        line-height: 2.4;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }

    .column .article .arc_mid .van-cell .van-cell__label {
        margin-top: 1rem;
    }

    .column .article .arc_mid .van-cell .van-cell__label .flag {
        margin-right: .4rem;
        font-size: 1.6rem;
        height: 2rem;
        padding: 0 .3rem;
    }

    .column .article .arc_mid .van-cell .van-cell__label .flag::before {
        border: .05rem solid;
        border-radius: .3rem
    }

    .column .article .arc_mid .van-cell .van-cell__label .flag-title {
        margin-right: 0;
        font-size: 2rem;
    }

    .column .article .arc_mid .van-cell .van-cell__label .flag-date {
        margin-right: .4rem;
        font-size: 2rem;
    }

    .column .article .arc_mid .van-cell .van-cell__label .flag-read {
        margin-left: .8rem;
        font-size: 1.6rem;
    }

    // .column .arc_tags {
    //   margin-left: 15px;
    //   padding: 4px 12px;
    //   font-size: 14px;
    // }

    .arc_tab .van-tab {
        padding: 0 .4rem;
    }

    .arc_tab .van-tab .van-tab__text {
        // position: fixed !important;
        // top: 54px;
        font-size: 1.8rem;
    }

    .arc_tab .van-tabs__wrap {
        padding: 0 .5rem;
    }

    .arc_tab .van-tabs__content {
        margin-top: 0;
    }

    .arc_tab .van-tabs__nav--line {
        padding-bottom: .1rem;
    }

    .arc_tab .van-tabs__nav--line.van-tabs__nav--complete {
        padding-right: 4rem;
    }

    .arc_tab .van-tab__text {
        border-radius: 3rem;
        padding: .5rem .8rem;
        background-color: #f0f0f0;
        font-weight: normal;
        width: 100%;
        text-align: center;
        height: 2rem;
        line-height: 2rem;
    }

    .arc_tab .van-tab--active .van-tab__text {
        background-color: #dfefff;
        font-weight: normal;
        width: 100%;
        text-align: center;
    }

    .arc_tab .van-tabs__line {
        display: none;
    }


</style>
